<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>List Jawaban</strong>
        </h5>
        <a-divider></a-divider>
        <a-form
          :form="form"
          @submit="handleSubmit"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <div class="d-flex">
            <a-form-item>
              <a-select
                style="
                  width: 180px;
                  z-index: 99;
                  border: 1px solid black;
                  border-radius: 4px;
                  margin-right: 10px;
                "
                v-decorator="[
                  'program',
                  {
                    rules: [
                      { required: true, message: 'Program harap dipilih!' },
                    ],
                  },
                ]"
                placeholder="Pilih Program"
                @change="handleChangeProgram"
              >
                <a-select-option
                  v-for="(i, index) in programTypeItems"
                  :key="index"
                  :value="i.id"
                >
                  {{ i.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                style="
                  min-width: 750px;
                  max-width: 850px;
                  z-index: 99;
                  border: 1px solid black;
                  border-radius: 4px;
                  height: fit-content;
                "
                v-decorator="[
                  'question',
                  {
                    rules: [
                      { required: true, message: 'Pertanyaan harap dipilih!' },
                    ],
                  },
                ]"
                placeholder="Pilih Pertanyaan"
                @change="handleChangeQuestion"
              >
                <a-select-option
                  v-for="(quest, index) in questionsItems"
                  :key="index"
                  :value="quest.id"
                >
                  <label for="" class="mr-1 m-0">{{ quest.code }}.</label>
                  {{ quest.question }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>

          <div class="mb-3">
            <a-spin size="large" :spinning="loading">
              <a-skeleton active :loading="loading"> </a-skeleton>
            </a-spin>
            <table
              class="table"
              style="
                background-color: #fafafa;
                border-bottom: 1px solid #e8e8e8;
              "
              v-if="!loading"
            >
              <thead class="col-12 row">
                <tr class="col-12 row">
                  <th for="" class="col-5 m-0">Jawaban</th>
                  <th for="" class="col-3 m-0">ID Pertanyaan Selanjutnya</th>
                  <th for="" class="col m-0">Hasil Survey</th>
                  <th for="" class="col m-0">Action</th>
                </tr>
              </thead>
              <tbody class="col-12 row">
                <tr
                  v-for="(item, index) in answerItems"
                  :key="index"
                  class="col-12 row"
                >
                  <td class="col-5 m-0">
                    <a-form-item>
                      <a-input
                        v-decorator="[
                          `answer${index + 1}`,
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Pertanyaan harap dipilih!',
                              },
                            ],
                            initialValue: item.answer,
                          },
                        ]"
                        @input="(e) => handleChangeInputAnswer(e, index)"
                        placeholder="Isi jawaban...."
                      ></a-input>
                    </a-form-item>
                  </td>
                  <td class="col-3 m-0">
                    <a-form-item>
                      <a-select
                        style="
                          width: 100%;
                          z-index: 99;
                          border: 1px solid black;
                          border-radius: 4px;
                          height: fit-content;
                        "
                        v-decorator="[
                          `nextQuestion${index + 1}`,
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Pertanyaan harap dipilih!',
                              },
                            ],
                            initialValue: item.nextQuestion
                              ? item.nextQuestion.id
                              : 'Tidak ada Lanjutan',
                          },
                        ]"
                        placeholder="Pilih Pertanyaan"
                        @change="(e) => handleChangeNextQuestionId(e, index)"
                      >
                        <a-select-option :value="'null'" :key="99">
                          Tidak ada lanjutan
                        </a-select-option>
                        <a-select-option
                          v-for="(
                            quest, index
                          ) in questionsItemsWithoutSelected"
                          :key="index"
                          :value="quest.id"
                        >
                          {{ quest.question }}
                          <label for="" class="m-0">{ {{ quest.code }} }</label>
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <!-- {{ item.nextQuesion ? item.nextQuesion.code : '-' }} -->
                  </td>
                  <td class="col m-0">
                    <a-form-item>
                      <a-input
                        v-decorator="[
                          `result${index + 1}`,
                          {
                            rules: [
                              {
                                required:
                                  form.getFieldValue(
                                    `nextQuestion${index + 1}`,
                                  ) === 99,
                                message: 'Hasil harap diisi!',
                              },
                            ],
                            initialValue: item.result,
                          },
                        ]"
                        :disabled="
                          !!item.nextQuestion &&
                          form.getFieldValue(`nextQuestion${index + 1}`) !==
                            'null'
                        "
                        placeholder="Isi hasil..."
                        @input="(e) => handleChangeResult(e, index)"
                      ></a-input>
                    </a-form-item>
                  </td>
                  <td class="col m-0">
                    <a-button
                      icon="delete"
                      type="danger"
                      @click="handleDeleteAnswerItem(index)"
                    ></a-button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <div v-if="answerItems.length < 3">
                  <a-button type="link" @click="handleAddNewAnswer">
                    + Tambah Jawaban
                  </a-button>
                </div>
              </tfoot>
            </table>
          </div>

          <a-button
            type="primary"
            class="px-5"
            :disabled="submitting || !answerItems.length"
            html-type="submit"
          >
            Simpan Data
          </a-button>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
// import router from '@/router'
// import { create } from '@/services/axios/api/obat'
import { getProgramTypeAll } from '@/services/axios/api/program'
import {
  // getAllSurvey,
  getSurveyByProgram,
  createAnswerQuestionSurvey,
} from '@/services/axios/api/survey'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
}
const wrapperColAnswer = {
  xs: { span: 24 },
  sm: { span: 24 },
}
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      wrapperColAnswer,
      programTypeItems: [],
      questionsItems: [],
      questionsItemsWithoutSelected: [],
      answerItems: [],
      submitting: false,
      loading: false,
      statusList: [
        { id: 1, name: 'Aktif' },
        { id: 0, name: 'Tidak Aktif' },
      ],
      questionId: '',
      programTypeId: '',
    }
  },
  created() {
    this.getProgramTypeAll()
    if (this.$route.query) {
      this.handleFetchWithQuery()
      this.questionId = this.$route.query.questionId
      this.programTypeId = this.$route.query.programTypeId
    }
    console.log(this.$route.query)
  },
  methods: {
    async getProgramTypeAll() {
      const res = await getProgramTypeAll()
      this.programTypeItems = res.data
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          // this.submitting = true
          console.log('Received values of form: ', values)
          const payload = {
            answers: this.answerItems,
            questionId: this.questionId || values.question,
          }
          console.log(payload, '<<<')
          try {
            const res = await createAnswerQuestionSurvey(payload)
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil ditambahkan',
                duration: 5,
              })
              // router.push('/obat')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
    handleChangeInputAnswer(e, id) {
      this.answerItems[id].answer = e.target.value
      console.log(this.answerItems)
    },
    handleChangeNextQuestionId(e, id) {
      // console.log(e, '<<<<')
      this.answerItems[id].nextQuestion = e
      console.log(this.answerItems)
    },
    handleChangeResult(e, id) {
      this.answerItems[id].result = e.target.value
      console.log(this.answerItems)
    },
    async handleFetchWithQuery() {
      await this.handleChangeProgram(this.$route.query.programTypeId)
      await this.handleChangeQuestion(this.$route.query.questionId)
    },
    async handleChangeProgram(value) {
      this.loading = true
      const resQuestion = await getSurveyByProgram(value)
      setTimeout(() => {
        this.questionsItems = resQuestion.data
        this.answerItems = []
        this.form.setFieldsValue({ question: 'Pilih Pertanyaan' })
        this.form.setFieldsValue({ program: value })
        this.programTypeId = value
        this.loading = false
      }, 750)
    },
    async handleChangeQuestion(value) {
      this.loading = true
      setTimeout(() => {
        const question = this.questionsItems.find((quest) => quest.id === value)
        const answer = question.responses
        this.questionsItemsWithoutSelected = this.questionsItems.filter(
          (quest) => quest.id !== value,
        )
        this.answerItems = answer
        this.form.setFieldsValue({ question: value })
        this.questionId = value
        this.loading = false
      }, 750)
    },
    handleAddNewAnswer() {
      this.answerItems.push({
        answer: '',
        nextQuestion: '99',
        result: '',
      })
      console.log(this.answerItems, '<<<<ANSWER')
    },
    handleDeleteAnswerItem(index) {
      this.answerItems = this.answerItems.filter((ans, i) => i !== index)
    },
  },
}
</script>
